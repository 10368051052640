<template>
    
    <div class="container">
        <Preloader/>
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header py-3">
                        <h5 class="mb-0">Biling details</h5>
                    </div>
                    <div class="card-body">
                        <form>
                            <!-- Text input -->
                            <div class="form-outline mb-4">
                                <input type="text" id="form7Example3" class="form-control"/>
                                <label class="form-label" for="form7Example3">Company name</label>
                            </div>

                            <!-- Text input -->
                            <div class="form-outline mb-4">
                                <input type="text" id="form7Example4" class="form-control"/>
                                <label class="form-label" for="form7Example4">Address</label>
                            </div>

                            <!-- Number input -->
                            <div class="form-outline mb-4">
                                <input type="number" id="form7Example6" class="form-control"/>
                                <label class="form-label" for="form7Example6">Phone</label>
                            </div>

                            <!-- Message input -->
                            <div class="form-outline mb-4">
                                <textarea class="form-control" id="form7Example7" rows="4"></textarea>
                                <label class="form-label" for="form7Example7">Additional information</label>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import Nav from "@/components/Nav.vue";

    export default {
        name: "Address",
        components: {
            Nav
        },
        mounted() {

            function fillInAddress() {
                // Get the place details from the autocomplete object.
                const place = autocomplete.getPlace();
                let address1 = "";
                let postcode = "";

                // Get each component of the address from the place details,
                // and then fill-in the corresponding field on the form.
                // place.address_components are google.maps.GeocoderAddressComponent objects
                // which are documented at http://goo.gle/3l5i5Mr
                for (const component of place.address_components) {
                    const componentType = component.types[0];

                    switch (componentType) {
                        case "street_number": {
                            address1 = `${component.long_name} ${address1}`;
                            break;
                        }

                        case "route": {
                            address1 += component.short_name;
                            break;
                        }

                        case "postal_code": {
                            postcode = `${component.long_name}${postcode}`;
                            break;
                        }

                        case "postal_code_suffix": {
                            postcode = `${postcode}-${component.long_name}`;
                            break;
                        }
                        case "locality":
                            document.querySelector("#locality").value = component.long_name;
                            break;
                        case "administrative_area_level_1": {
                            document.querySelector("#state").value = component.short_name;
                            break;
                        }
                        case "country":
                            document.querySelector("#country").value = component.long_name;
                            break;
                    }
                }

                address1Field.value = address1;
                postalField.value = postcode;
                // After filling the form with address components from the Autocomplete
                // prediction, set cursor focus on the second address line to encourage
                // entry of subpremise information such as apartment, unit, or floor number.
                address2Field.focus();
            }


        },
    }

</script>

<style>
    .container {
        max-width: 1024px;
    }
    .card{
        margin-top: 75px;
    }
    .empty-layout{
        height: 100vh!important;
    }
</style>
